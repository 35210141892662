<template>
<div class="main">
  <div class="top-bg">
    <img
      class="don-bg"
      src="../assets/donations-back.jpg"
    />
  </div>
  <div class="big-white-box donation">
    <div class="donation-wrap">
      <h1 class="top-head">
        הזמנת סיכות דם המכבים
        <br />

        לארגונים וחברות  2025
      </h1>
      <p
        v-if="formStatus != 'success'"
        class="txt24 center"
      >
        תודה רבה על תמיכתכם במיזם דם המכבים!
        <br />
        להלן טופס הזמנת סיכות.
        <br />
        <br />
        <b> מחירון סיכות בכמויות גדולות:
        </b> <br>
        עד 300 סיכות: 18 ש"ח / 10 ש"ח לאמיתי / דמוי
        <br>
        300 עד 1,000 סיכות: 15 ש"ח / 8 ש"ח
        <br>
        מעל 1,000: 13 ש"ח / 6.5 ש"ח
        <br>
        כעמותה רשומה, איננו גובים מע"מ.

      </p>
    </div>
    <div class="form">
      <form
        @submit.prevent="sendForm"
        v-if="formStatus != 'success'"
      >
        <div class="side-by">
          <input
            v-model="form.name"
            placeholder="שם גוף מזמין"
            type="text"
            required
            class="small-txt-field"
          />
          <input
            v-model="form.id"
            placeholder="מספר ח״פ / ע״ר"
            type="number"
            required
            class="small-txt-field"
          />
        </div>
        <div class="side-by">
          <input
            v-model="form.contactName"
            placeholder="שם מלא של איש הקשר"
            type="text"
            required
            class="small-txt-field"
          />
          <input
            v-model="form.contactPhone"
            placeholder="מספר נייד איש הקשר"
            type="tel"
            required
            class="small-txt-field"
          />

          <input
            v-model="form.contactMail"
            placeholder="מייל איש הקשר"
            type="email"
            required
            pattern="^[\w.-]+@[\w]+[\w-]*(?:\.[\w-]+)*(?:\.[\w-]{2,})+$"
            class="small-txt-field"
          />
        </div>

        <div class="comp-pick-wrap">
          <div class="comp-flower">
            <p class="flower-type-head">
              פרח
              <br />

              דם המכבים
            </p>
            <img
              src="../assets/buy1.jpg"
              alt="פרח אמיתי"
            />
            <p class="">כמות סיכות</p>
            <input
              v-model="form.flowerNum"
              placeholder="0"
              type="number"
              required
              class="small-txt-field"
            />
          </div>
          <div class="comp-flower">
            <p class="flower-type-head">
              דמוי פרח
              <br />

              דם המכבים
            </p>
            <img
              src="../assets/buy2.jpg"
              alt="פרח פלסטיק"
            />
            <p class="">כמות סיכות</p>
            <input
              v-model="form.likeNum"
              placeholder="0"
              type="number"
              required
              class="small-txt-field"
            />
          </div>
        </div>
        <p class="small-form-head">האם תרצו להזין סכום נוסף תרומה לעמותה? </p>

        <input
          v-model="form.donation"
          placeholder="0"
          type="number"
          class="small-txt-field"
        />

        <p class="small-form-head">כתובת למשלוח</p>
        <div class="side-by">
          <input
            v-model="form.city"
            placeholder="עיר"
            type="text"
            required
            class="small-txt-field"
          />
          <input
            v-model="form.street"
            placeholder="רחוב"
            type="text"
            required
            class="small-txt-field"
          />
          <input
            v-model="form.locationNum"
            placeholder="מספר בית / דירה / קומה"
            type="text"
            required
            class="small-txt-field"
          />
          <input
            v-model="form.zip"
            placeholder="מיקוד"
            type="number"
            required
            class="small-txt-field"
          />
        </div>

        <div class="side-by">
          <select
            class="small-txt-field"
            v-model="form.paymentMethod"
          >
            <option
              value=""
              selected
              disabled
              hidden
            >צורת תשלום</option>
            <option value="credit">כרטיס אשראי</option>
            <option value="bank">העברה בנקאית</option>
            <option value="check">צ'ק לפקודת דם המכבים</option>
          </select>

          <label class="checkbox-input">
            <input
              v-model="form.delivery"
              class="bgl-checkbox"
              type="checkbox"
            />
            <span class="checkbox">✔</span>
            מאשר שליח עד הארגון (תוספת 50 ש״ח)
          </label>
        </div>
        <div class="side-by">
          <input
            v-model="form.formName"
            placeholder="שם ממלא טופס ההזמנה"
            type="text"
            required
            class="small-txt-field"
          />

          <label class="checkbox-input">
            <input
              v-model="form.approval"
              class="bgl-checkbox"
              type="checkbox"
            />
            <span class="checkbox">✔</span>
            אני מאשר/ת את פרטי הזמנה זו.
          </label>
        </div>

        <textarea
          v-model="form.message"
          class="small-txt-field"
          placeholder="הערות כלליות נוספות?"
        ></textarea>
        <label class="checkbox-input logo-use">
          <input
            v-model="form.logoUse"
            class="bgl-checkbox"
            type="checkbox"
          />
          <span class="checkbox">✔</span>
          האם אתם מאפשרים שימוש בלוגו שלכם לפרסום השתתפותכם במיזם?
        </label>
        <div class="school-end">
          <p>
            <span>{{ form.likeNum }}</span>
            סיכות פלסטיק
          </p>
          <p>
            <span>{{ form.flowerNum }}</span>
            סיכות פרח אמיתי
          </p>
          <!-- <p>
            מחיר כולל משלוח

            <span>{{ totalPrice }}</span>
            ₪
          </p> -->
        </div>
        <input
          class="btn cont"
          type="submit"
          :value="actionText ? actionText : $l('Submit')"
        />
      </form>

      <div
        v-if="formStatus == 'fail'"
        class="form-fail"
      >
        <p>{{ $l("error") }}</p>
      </div>
      <div
        v-if="formStatus == 'success'"
        class="form-success"
      >
        <p class="red-txt">תודה רבה על תמיכתכם במיזם דם המכבים!</p>
        <div class="end-txt">
          <p class="txt20">
            הזמנתכם התקבלה ותישלח אליכם בימים הקרובים. (ניתן להוריד מעמוד זה
            מסמכים רלוונטים להנהלת חשבונות בקישורים מטה) לכל שאלה אנחנו זמינים
            במייל
            <a
              class="small-link"
              href="mailto:info@redeverlasting.com"
            >info@redeverlasting.com</a>
          </p>
        </div>
      </div>
    </div>

    <p
      v-if="formStatus != 'success'"
      class="end-txt"
    >
      תודה שבחרתם לקח חלק במיזם, (ניתן להוריד מעמוד זה מסמכים רלוונטים להנהלת
      חשבונות בקישורים מטה) לכל שאלה אנחנו זמינים במייל
      <a href="mailto:info@redeverlasting.com">info@redeverlasting.com</a>
    </p>
    <div class="download-docs">
      <a
        href="/docs/Confirmation-of-account-ownership-2024.pdf"
        download
      >אישור בעלות חשבון 2024</a>
      <a
        href="/docs/Proper-management-2024.pdf"
        download
      >ניהול תקין 2024</a>
      <a
        href="/docs/Tax-deduction-+-book-management-2024.pdf"
        download
      >
        ניכוי מס+ניהול ספרים 2024</a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  computed: {
    totalPrice() {
      let total = 0;
      const plasticPrice = 10;
      const flowerPrice = 18;
      total += flowerPrice * this.form.flowerNum;
      total += plasticPrice * this.form.likeNum;
      total += 50;
      return total;
    },
  },
  data() {
    return {
      formStatus: "",
      form: {
        name: "",
        id: "",
        contactName: "",
        contactPhone: "",
        contactMail: "",
        flowerNum: 0,
        likeNum: 0,
        donation: "",
        city: "",
        street: "",
        locationNum: "",
        zip: "",
        paymentMethod: "",
        delivery: "",
        formName: "",
        approval: "",
        message: "",
        totalPrice: "",
        logoUse: "",
        emailTo: "mickey@redeverlasting.com"

      },
    };
  },
  methods: {
    async sendForm() {
      if (this.actionText === "שולח") return;
      try {
        this.form.totalPrice = this.totalPrice;
        this.actionText = "שולח";
        await this.$db.collection("companies").post(this.form);
        this.actionText = "נשלח";
        this.formStatus = "success";
      } catch (err) {
        this.actionText = "שליחה";
        console.log(err.response.data);
        this.formStatus = "fail";
      }
    },
  },
};
</script>

<style scoped>
.comp-pick-wrap {
  text-align: center;
  padding: 30px;
  margin: 0 10px 20px;
  width: 100%;
  display: flex;
  gap: 40px;
  background: #f8f7f7;
  flex-wrap: wrap;
}

.comp-flower {
  flex-basis: calc(50% - 20px);
  flex-grow: 1;
  border: 1px solid #bbc3d7;
  padding: 0 20px 20px;
  background: white;
  min-width: 240px;
}

.comp-flower .small-txt-field {
  margin: -10px 0 0 0;
}

.comp-flower img {
  height: 200px;
  margin: -20px;
}

.school-end {
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
}

.school-end p {
  margin: 0 0 5px 0;
}

.school-end span {
  font-weight: 600;
}

.small-form-head {
  margin: 10px;
}

.checkbox-input {
  position: relative;
  padding-right: 40px;
  cursor: pointer;
  transition: all ease 200ms;
  min-width: 50%;
}

.checkbox-input .checkbox {
  width: 16px;
  height: 16px;
  background: rgb(255, 255, 255);
  border: #1d3779 1px solid;
  border-radius: 2px;
  position: absolute;
  top: 2px;
  right: 15px;
  color: transparent;
}

.checkbox-input .bgl-checkbox:checked+.checkbox {
  background: #1d3779;
  color: white;
  font-size: 12px;
  text-align: center;
}

.small-txt-field {
  background: #f1f1f1;
  margin: 0 10px;
  font-family: inherit;
  font-size: 16px;
  width: 100%;
  padding: 10px;
  border: none;
  resize: vertical;
}

.small-txt-field:focus-visible {
  outline: #1d3779 1px solid;
}

.form form {
  max-width: 760px;
  display: flex;
  flex-wrap: wrap;
  margin: 30px auto;
  width: 90%;
}

.side-by {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}

.small-form-txt {
  margin: -20px 10px 20px;
  font-size: 14px;
}

.btn.cont {
  margin: 10px auto;
}

.main {
  min-height: calc(100vh - 130px);
}

.logo-use {
  margin: 1rem auto;
}

@media screen and (max-width: 991px) {
  .side-by {
    display: block;
    margin-bottom: 0px;
  }

  .small-txt-field {
    margin: 0 0px 20px;
  }

  .checkbox-input {
    margin: -10px 0px 20px;
    display: block;
  }

  .comp-pick-wrap {
    text-align: center;
    padding: 0px;
    margin: 0 0px 20px;
    width: 100%;
    display: flex;
    gap: 20px;
    background: transparent;
    flex-wrap: wrap;
  }

  .comp-flower {
    flex-basis: calc(50% - 20px);
    flex-grow: 1;
    border: 1px solid #bbc3d7;
    padding: 0 20px 20px;
    background: white;
    min-width: 240px;
  }
}
</style>
